export const COMPONENT_CATEGORIES = [
  'accessory',
  'illustration',
  'form',
  'table',
  'typography',
  'layout',
  'navigation',
  'spreadsheet',
  'formatter',
] as const;

// Mapping of component slugs to Pascal-cased, period separated component names
export const COMPONENT_SLUGS_TO_NAMES = {
  agreement: 'Agreement',
  agreementalert: 'AgreementAlert',
  alert: 'Alert',
  alertconsumer: 'AlertConsumer',
  alertcontextprovider: 'AlertContext.Provider',
  alertprovider: 'AlertProvider',
  anchor: 'Anchor',
  autograph: 'Autograph',
  avatar: 'Avatar',
  banner: 'Banner',
  belt: 'Belt',
  block: 'Block',
  box: 'Box',
  breadcrumb: 'Breadcrumb',
  breadcrumbanchor: 'Breadcrumb.Anchor',
  breadcrumbbutton: 'Breadcrumb.Button',
  breadcrumbelement: 'Breadcrumb.Element',
  bubble: 'Bubble',
  button: 'Button',
  buttongroup: 'ButtonGroup',
  buttongrouptoggle: 'ButtonGroup.Toggle',
  calloutbox: 'CalloutBox',
  checkbox: 'Checkbox',
  column: 'Column',
  component: 'Component',
  contextprovider: 'ContextProvider',
  currency: 'Currency',
  current: 'Current',
  currentitem: 'Current.Item',
  customoutliner: 'CustomOutliner',
  customtext: 'CustomText',
  datepicker: 'DatePicker',
  deprecatedcomponent: 'DeprecatedComponent',
  deprecateddropdown: 'DeprecatedDropdown',
  deprecateddropdownitem: 'DeprecatedDropdownItem',
  display: 'Display',
  divider: 'Divider',
  dropdown: 'Dropdown',
  dropdownanchor: 'Dropdown.Anchor',
  dropdownbelt: 'Dropdown.Belt',
  dropdownbutton: 'Dropdown.Button',
  dropdowncheckbox: 'Dropdown.Checkbox',
  dropdownheader: 'Dropdown.Header',
  dropdownitem: 'Dropdown.Item',
  dropdownradio: 'Dropdown.Radio',
  dropdownsearch: 'Dropdown.Search',
  dropdownseparator: 'Dropdown.Separator',
  dropdowntooltip: 'Dropdown.Tooltip',
  dropdowntrigger: 'Dropdown.Trigger',
  emptystate: 'EmptyState',
  environmentoutliner: 'EnvironmentOutliner',
  expose: 'Expose',
  favorite: 'Favorite',
  field: 'Field',
  filepicker: 'FilePicker',
  form: 'Form',
  formsection: 'FormSection',
  formattedbigcurrency: 'FormattedBigCurrency',
  formatteddate: 'FormattedDate',
  formattedmultiple: 'FormattedMultiple',
  formattednumber: 'FormattedNumber',
  formattedpercentage: 'FormattedPercentage',
  fullpagecelebration: 'FullPageCelebration',
  fullpagemodal: 'FullPageModal',
  fullpagespreadsheet: 'FullPageSpreadsheet',
  fullpagespreadsheetv2: 'FullPageSpreadsheetV2',
  grid: 'Grid',
  hstack: 'HStack',
  header: 'Header',
  heading1: 'Heading1',
  heading2: 'Heading2',
  heading3: 'Heading3',
  heading4: 'Heading4',
  help: 'Help',
  horizontalnav: 'HorizontalNav',
  horizontalnavanchor: 'HorizontalNav.Anchor',
  horizontalnavbelt: 'HorizontalNav.Belt',
  horizontalnavbutton: 'HorizontalNav.Button',
  horizontalnavitem: 'HorizontalNav.Item',
  icon: 'Icon',
  iconwithtext: 'IconWithText',
  inkoutliner: 'InkOutliner',
  input: 'Input',
  ledger: 'Ledger',
  ledgerwrapperactions: 'LedgerWrapper.Actions',
  ledgerwrapperbottombar: 'LedgerWrapper.BottomBar',
  ledgerwrapperfilter: 'LedgerWrapper.Filter',
  ledgerwrapperpagination: 'LedgerWrapper.Pagination',
  ledgerwrappersearch: 'LedgerWrapper.Search',
  ledgerwrappertopbar: 'LedgerWrapper.TopBar',
  li: 'Li',
  list: 'List',
  listbody: 'ListBody',
  listcontrol: 'ListControl',
  listitem: 'ListItem',
  loader: 'Loader',
  loaderprovider: 'LoaderProvider',
  modal: 'Modal',
  modalcontent: 'ModalContent',
  modalcontextprovider: 'ModalContext.Provider',
  modaldetail: 'ModalDetail',
  modalprovider: 'ModalProvider',
  monologue: 'Monologue',
  multistepmodal: 'MultiStepModal',
  nav: 'Nav',
  newcheckbox: 'NewCheckbox',
  newdatepicker: 'NewDatePicker',
  newdatepickercalendar: 'NewDatePicker.Calendar',
  newdatepickershortcuts: 'NewDatePicker.Shortcuts',
  newformsection: 'NewFormSection',
  newformsectioncontent: 'NewFormSection.Content',
  newformsectionerror: 'NewFormSection.Error',
  newformsectionsubtitle: 'NewFormSection.Subtitle',
  newformsectiontitle: 'NewFormSection.Title',
  newinput: 'NewInput',
  newradio: 'NewRadio',
  newselect: 'NewSelect',
  newselectgroup: 'NewSelect.Group',
  newselectoption: 'NewSelect.Option',
  newtable: 'NewTable',
  newtablebody: 'NewTable.Body',
  newtablecell: 'NewTable.Cell',
  newtablefoot: 'NewTable.Foot',
  newtablehead: 'NewTable.Head',
  newtableheadcell: 'NewTable.HeadCell',
  newtableorderablebody: 'NewTable.OrderableBody',
  newtableorderablerow: 'NewTable.OrderableRow',
  newtablepin: 'NewTable.Pin',
  newtableribbon: 'NewTable.Ribbon',
  newtablerow: 'NewTable.Row',
  newtableshimmer: 'NewTable.Shimmer',
  newtablestripe: 'NewTable.Stripe',
  newtabletwiddle: 'NewTable.Twiddle',
  newtabletwiddleexpandall: 'NewTable.Twiddle.ExpandAll',
  newtabletwiddleicon: 'NewTable.Twiddle.Icon',
  newtextarea: 'NewTextArea',
  newtextareacounter: 'NewTextArea.Counter',
  newtypeahead: 'NewTypeahead',
  ol: 'Ol',
  page: 'Page',
  pagebody: 'Page.Body',
  pagecontent: 'Page.Content',
  pageheader: 'Page.Header',
  pagesidebar: 'Page.Sidebar',
  pagetitle: 'Page.Title',
  pagekit: 'PageKit',
  pagekitaccessory: 'PageKit.Accessory',
  pagekitcontentsection: 'PageKit.ContentSection',
  pagekitfooter: 'PageKit.Footer',
  pagekitheader: 'PageKit.Header',
  pagekitnavigation: 'PageKit.Navigation',
  pagination: 'Pagination',
  paragraph: 'Paragraph',
  placeholder: 'Placeholder',
  progress: 'Progress',
  progresslegenditem: 'Progress.LegendItem',
  radio: 'Radio',
  replacement: 'Replacement',
  replacementitem: 'Replacement.Item',
  restfiles: 'RestFiles',
  restledger: 'RestLedger',
  row: 'Row',
  search: 'Search',
  section: 'Section',
  select: 'Select',
  selectablelist: 'SelectableList',
  shimmer: 'Shimmer',
  shimmercircle: 'Shimmer.Circle',
  shimmerrectangle: 'Shimmer.Rectangle',
  shimmertext: 'Shimmer.Text',
  sidenav: 'SideNav',
  sidenavcontentcontainer: 'SideNav.ContentContainer',
  sidenavfilter: 'SideNav.Filter',
  sidenavgroup: 'SideNav.Group',
  sidenavgroupitem: 'SideNav.GroupItem',
  sidenavgroupitems: 'SideNav.GroupItems',
  sidenavgroups: 'SideNav.Groups',
  sidenavgrouptoggle: 'SideNav.GroupToggle',
  sidenavitem: 'SideNav.Item',
  sidenavsectionlabel: 'SideNav.SectionLabel',
  sidenavstickycontainer: 'SideNav.StickyContainer',
  sidenavtoggle: 'SideNav.Toggle',
  sidenavcontextprovider: 'SideNav.Context.Provider',
  sitekit: 'SiteKit',
  sitekitcontent: 'SiteKit.Content',
  sitekitheader: 'SiteKit.Header',
  sitekitheadersignpost: 'SiteKit.Header.SignPost',
  sitekitheadersignpostsubtitle: 'SiteKit.Header.SignPost.Subtitle',
  sitekitheadersignposttitle: 'SiteKit.Header.SignPost.Title',
  sitekiticonanchor: 'SiteKit.IconAnchor',
  sitekiticonbutton: 'SiteKit.IconButton',
  sitekitlocalnav: 'SiteKit.LocalNav',
  sitekitsidebar: 'SiteKit.Sidebar',
  sitekitsidebarcontrols: 'SiteKit.Sidebar.Controls',
  sitekitsidebarheadlines: 'SiteKit.Sidebar.Headlines',
  sitekitsidebarheadlinesanchor: 'SiteKit.Sidebar.Headlines.Anchor',
  sitekitsidebarheadlinesbutton: 'SiteKit.Sidebar.Headlines.Button',
  sitekitsidebarutilities: 'SiteKit.Sidebar.Utilities',
  sitekitsidebarutilitiesanchor: 'SiteKit.Sidebar.Utilities.Anchor',
  sitekitsidebarutilitiesbutton: 'SiteKit.Sidebar.Utilities.Button',
  sitekitsidebarutilitiesgroup: 'SiteKit.Sidebar.Utilities.Group',
  sitekitsidebarworkspaceselector: 'SiteKit.Sidebar.WorkspaceSelector',
  sitekitsidebarworkspaceselectortitle: 'SiteKit.Sidebar.WorkspaceSelector.Title',
  sitekitheaderbutton: 'SiteKitHeaderButton',
  slider: 'Slider',
  slidererror: 'SliderError',
  slidererrors: 'SliderErrors',
  small: 'Small',
  spreadsheet: 'Spreadsheet',
  spreadsheetv2: 'SpreadsheetV2',
  staffoutliner: 'StaffOutliner',
  staffer: 'Staffer',
  status: 'Status',
  subnav: 'SubNav',
  subnavanchor: 'SubNav.Anchor',
  subnavbutton: 'SubNav.Button',
  subnavitem: 'SubNav.Item',
  subset: 'Subset',
  table: 'Table',
  tablebodyrow: 'TableBodyRow',
  tablecell: 'TableCell',
  tabletwiddlerow: 'TableTwiddleRow',
  tag: 'Tag',
  tagbutton: 'Tag.Button',
  tagcontainer: 'Tag.Container',
  tagdraggable: 'Tag.Draggable',
  textarea: 'TextArea',
  tile: 'Tile',
  tilerow: 'TileRow',
  tiles: 'Tiles',
  tooltip: 'Tooltip',
  tracker: 'Tracker',
  typeahead: 'Typeahead',
  ul: 'Ul',
  vstack: 'VStack',
  verticalnav: 'VerticalNav',
  verticalnavanchor: 'VerticalNav.Anchor',
  verticalnavbutton: 'VerticalNav.Button',
  verticalnavitem: 'VerticalNav.Item',
  vignette: 'Vignette',
} as const;

export const DEPRECATED_COMPONENT_NAMES = [
  'Belt',
  'Checkbox',
  'DatePicker',
  'DeprecatedDropdown',
  'DeprecatedDropdownItem',
  'FormSection',
  // FEP: 'FullPageSpreadsheet',
  'Input',
  'Ledger',
  'ModalContent',
  'Nav',
  'Radio',
  'Select',
  // FEP: 'Spreadsheet',
  'Staffer',
  'Table',
  'TableBodyRow',
  'TableCell',
  'TableTwiddleRow',
  'TextArea',
  'TileRow',
  'Typeahead',
] as const;

export const DEPRECATION_DATES_FOR_DEPRECATED_COMPONENTS = {
  Belt: 'May 1, 2023',

  DeprecatedDropdown: 'Jul 1, 2023',
  DeprecatedDropdownItem: 'Jul 1, 2023',

  Checkbox: 'Sep 1, 2023',
  DatePicker: 'Sep 1, 2023',
  FormSection: 'Sep 1, 2023',
  FullPageSpreadsheet: 'Sep 1, 2023',
  Input: 'Sep 1, 2023',
  ModalContent: 'Sep 1, 2023',
  Nav: 'Sep 1, 2023',
  Radio: 'Sep 1, 2023',

  Ledger: 'Dec 1, 2023',
  Select: 'Dec 1, 2023',
  Spreadsheet: 'Dec 1, 2023',
  Staffer: 'Dec 1, 2023',
  Table: 'Dec 1, 2023',
  TableBodyRow: 'Dec 1, 2023',
  TableCell: 'Dec 1, 2023',
  TableTwiddleRow: 'Dec 1, 2023',
  TextArea: 'Dec 1, 2023',
  TileRow: 'Dec 1, 2023',
  Typeahead: 'Dec 1, 2023',
} as const;
