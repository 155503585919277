import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { DEPRECATED_COMPONENT_NAMES } from '../../../constants';
import { getUsageDataGenerationDate } from '../../../helpers/usage';
import Content from '../../../components/Content/Content';
import Layout from '../../../components/Layout';
import Top from '../../../components/Usage/Top';
import StyledLink from '../../../components/Common/StyledLink';

const UsageComponentsPage = () => {
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usageCounts, setUsageCounts] = useState({ componentNamesCount: 0, repos: { 'Loading…': 0 }, total: 0 });
  const [usageData, setUsageData] = useState({});

  useEffect(() => {
    fetch(withPrefix('datasets/usage.json'))
      .then(response => response.json())
      .then(data => {
        setUsageData(data);

        const o = { componentNamesCount: 0, repos: {}, total: 0 };

        Object.keys(data)
          .filter(d => !['count', 'date', 'locations', 'names', 'paths', 'repos'].includes(d))
          .forEach(c => {
            o[c] = data[c].count;
            o.componentNamesCount += 1;

            Object.keys(data[c])
              .filter(r => r !== 'count')
              .forEach(r => {
                o['repos'] = { ...o['repos'], [r]: (o['repos'][r] || 0) + data[c][r].count };
              });
          });

        o.total = data.count;
        setUsageCounts(o);
        setDate(getUsageDataGenerationDate(data.date));
        setIsLoading(false);
      });
  }, []);

  const { componentNamesCount, repos, total: componentsInUseCount, ...componentNames } = usageCounts;

  const formattedComponentNames = Object.keys(usageCounts)
    .filter(
      x =>
        ![
          'componentNamesCount',
          'formattedRepos',
          'locations',
          'names',
          'paths',
          'repos',
          'reposCount',
          'total',
        ].includes(x),
    )
    .sort();

  const formattedRepos = Object.keys(repos).sort().join(', ');
  const reposCount = Object.keys(repos).length;

  return (
    <Layout pageTitle="Usage / Components">
      <Top
        breadcrumbLinks={[{ children: 'Usage', to: '/usage' }]}
        title="Components"
        subtitle={`Component usage data as of ${date}`}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/usage/components/index.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading1>
                Carta is using {new Intl.NumberFormat().format(componentsInUseCount ?? 0)} instances of{' '}
                {new Intl.NumberFormat().format(componentNamesCount ?? 0)} ink components across{' '}
                {new Intl.NumberFormat().format(reposCount ?? 0)} repositories.
              </Ink.Heading1>

              <Ink.Paragraph>
                <code>{formattedRepos}</code>
              </Ink.Paragraph>

              <Ink.Box top="large">
                <Ink.HStack>
                  <Ink.Box minWidth={['none', '600px']}>
                    <Ink.Box top="large">
                      <StyledLink to="/usage/components/deprecated">See deprecated components only</StyledLink>
                    </Ink.Box>

                    <Ink.Box top="gutter">
                      {/* TODO: Add table sorting with the useSort hook */}
                      <Ink.NewTable>
                        <Ink.NewTable.Head>
                          <Ink.NewTable.Row>
                            <Ink.NewTable.HeadCell width="330px">Component</Ink.NewTable.HeadCell>
                            <Ink.NewTable.HeadCell>Additional info</Ink.NewTable.HeadCell>
                            <Ink.NewTable.HeadCell align="right">Count in use</Ink.NewTable.HeadCell>
                          </Ink.NewTable.Row>
                        </Ink.NewTable.Head>

                        <Ink.NewTable.Body>
                          {formattedComponentNames
                            .sort((n1, n2) => usageData[n2].count - usageData[n1].count)
                            .map(componentName => {
                              const componentCount = new Intl.NumberFormat().format(componentNames[componentName] ?? 0);
                              const isDeprecatedComponent = DEPRECATED_COMPONENT_NAMES.includes(componentName);

                              return (
                                <Ink.NewTable.Row key={componentName}>
                                  <Ink.NewTable.Cell>
                                    <StyledLink to={`/usage/components/${componentName}`}>{componentName}</StyledLink>
                                  </Ink.NewTable.Cell>

                                  <Ink.NewTable.Cell>
                                    {isDeprecatedComponent && (
                                      <Ink.Bubble variant="feedback-negative">Deprecated</Ink.Bubble>
                                    )}
                                  </Ink.NewTable.Cell>

                                  <Ink.NewTable.Cell align="right">{componentCount}</Ink.NewTable.Cell>
                                </Ink.NewTable.Row>
                              );
                            })}
                        </Ink.NewTable.Body>
                      </Ink.NewTable>
                    </Ink.Box>
                  </Ink.Box>
                </Ink.HStack>
              </Ink.Box>
            </Ink.Box>
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsageComponentsPage;
