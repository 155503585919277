import { COMPONENT_SLUGS_TO_NAMES } from '../constants';

export function getComponentNameFromSlug(slug: string): typeof COMPONENT_SLUGS_TO_NAMES {
  return COMPONENT_SLUGS_TO_NAMES[slug] ?? `${slug[0]?.toUpperCase()}${slug.slice(1)}`;
}

export function getUsageDataGenerationDate(date: string): string {
  const [year, month, day] = date.split('-');
  const utcDate = new Date(Date.UTC(+year, +month - 1, +day + 1));

  return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(utcDate);
}

// Check Banner-specific props
export function checkBannerSpecificProps({ name, key, value }: { name: string; key: string; value: string }): boolean {
  // TODO: Use types to make this universal for all use with all components, clean this logic up in general
  const bannerTypes = ['error', 'success', 'info', 'warning', 'setup', 'staff', 'upload'];

  return (
    (name === 'Banner' && key === 'type' && bannerTypes.includes(`${value}`)) ||
    (name === 'Banner' && key !== 'list' && key !== 'onClose') ||
    name !== 'Banner'
  );
}

/**
 * URLs in the usage data pages can look like one of the following:
 *
 * /usage
 * /usage/components
 * /usage/components/Component.Name
 * /usage/components/Component.Name/repository
 * /usage/components/Component.Name/repository/path/to/file.tsx
 * /usage/components/Component.Name/repository/path/to/file.tsx/L123C45:L678C90
 *
 * This function constructs a RegExp object that matches:
 * - the "usage" part of the url as a required constant
 * - the "component" part as an optional constant
 * - the "repo", "path", and "location" as optional dynamic strings
 *   following the usual character sets for these parts
 *
 * The RegExp is built in this way to separate each part and make it easier to understand. The full regex
 * expression can be inspected by looking at pattern.source:
 *
 * ^(\/)(?<usage>usage)(\/)?(?<components>components)?(\/)?(?<name>[\w+.]+)?(\/)?(?<repo>[a-z-]+)?(\/)?(?<path>[\w/.-]+.[jt]sx?)?(\/)?(?<location>L\d+C\d+:L\d+C\d+)?
 */
export const parseLocation = (
  url: string,
): {
  usage?: string;
  components?: string;
  name?: string;
  repo?: string;
  path?: string;
  location?: string;
} | null => {
  const usage = /(?<usage>usage)/;
  const components = /(?<components>components)?/;
  const name = /(?<name>[\w+.]+)?/;
  const repo = /(?<repo>[a-z-]+)?/;
  const path = /(?<path>[\w/.\-[\]]+.[jt]sx?)?/;
  const location = /(?<location>L\d+C\d+:L\d+C\d+)?/;
  const slash = /(\/)?/;

  const pattern = new RegExp(
    [/^(\/)/, usage, slash, components, slash, name, slash, repo, slash, path, slash, location]
      .map(r => r.source) // JavaScript doesn't let us compose RegExp expressions easily
      .join(''), // So we need to do this little song and dance
  );

  const match = url.match(pattern);

  if (!match) {
    return null;
  }

  return { ...match.groups };
};
